import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "tabs-panel-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_TabView = _resolveComponent("TabView")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode(_component_TabView, {
      class: "color-primary",
      activeIndex: $setup.active,
      "onUpdate:activeIndex": _cache[1] || (_cache[1] = ($event: any) => ($setup.active = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.arrHeader, (item, index) => {
          return (_openBlock(), _createBlock(_component_TabPanel, {
            key: index,
            header: item
          }, null, 8, ["header"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mb-6" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "color-primary font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")
  const _component_InputIconRightGrey = _resolveComponent("InputIconRightGrey")
  const _component_Column = _resolveComponent("Column")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Card = _resolveComponent("Card")
  const _component_ListCopyEditDel = _resolveComponent("ListCopyEditDel")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")
  const _component_DiscountRetailForm = _resolveComponent("DiscountRetailForm")
  const _component_Sidebar = _resolveComponent("Sidebar")

  return (_openBlock(), _createBlock("section", null, [
    _createVNode(_component_Card, null, {
      title: _withCtx(() => [
        _createVNode("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_Button, {
              label: "Tambah Diskon Retail",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redirectAdd())),
              icon: "pi pi-plus",
              class: "font-semibold p-button-sm btn-primary"
            })
          ]),
          _createVNode("div", null, [
            _createVNode(_component_InputIconRightGrey, {
              placeholder: "Cari Disini ...",
              type: _ctx.$enumBase.input.text,
              icon: _ctx.$icon.svg.searchEva
            }, null, 8, ["type", "icon"])
          ])
        ])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.data,
          class: "table-og",
          responsiveLayout: "scroll"
        }, {
          footer: _withCtx(() => [
            _createTextVNode(" Total diskon retail sebanyak " + _toDisplayString(_ctx.data ? _ctx.data.length : 0) + ". ", 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, { header: "Level" }, {
              body: _withCtx((slotProps) => [
                _createVNode("p", _hoisted_3, _toDisplayString(slotProps.data.Kelas), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "Jumlah Diskon" }, {
              body: _withCtx((slotProps) => [
                _createVNode("p", null, _toDisplayString((slotProps.data.Diskon) ? `${slotProps.data.Diskon}%` : '-'), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "Rata2 / Bulan (6 Bulan)" }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(" Rp " + _toDisplayString(new Number(slotProps.data.MinTransaksi).toLocaleString("id-ID")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, { header: "Aktif" }, {
              body: _withCtx((slotProps) => [
                _createVNode(_component_InputSwitch, {
                  class: "switch-og",
                  onChange: ($event: any) => (_ctx.changeIsActive(slotProps.data)),
                  modelValue: slotProps.data.IsActive,
                  "onUpdate:modelValue": ($event: any) => (slotProps.data.IsActive = $event)
                }, null, 8, ["onChange", "modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, null, {
              header: _withCtx(() => []),
              body: _withCtx((slotProps) => [
                _createVNode("i", {
                  class: "pi pi-ellipsis-h text-base cursor-pointer text-black",
                  "aria:haspopup": "true",
                  onClick: ($event: any) => (_ctx.showDropDown($event, slotProps.data.Id))
                }, null, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    }),
    _createVNode(_component_OverlayPanel, { ref: "op" }, {
      default: _withCtx(() => [
        _createVNode(_component_ListCopyEditDel, {
          del: true,
          edit: true,
          onDel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteData())),
          onEdit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.redirectEdit(_ctx.id)))
        })
      ]),
      _: 1
    }, 512),
    _createVNode(_component_Sidebar, {
      class: "type-bus-sidebar",
      visible: _ctx.sliderActive,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_ctx.sliderActive = $event)),
      showCloseIcon: false,
      position: "right"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DiscountRetailForm, {
          id: _ctx.id,
          onReloadData: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getAllData())),
          onCloseSidebar: _ctx.closeSidebar
        }, null, 8, ["id", "onCloseSidebar"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
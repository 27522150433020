import {
  ref, watch, onMounted, getCurrentInstance
} from 'vue'
import { discountRetailUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import DropdownAbsolute from '@/views/shared-components/DropdownAbsolute.vue'
import DiscountRetailForm from '@/views/master/customers/discount/discount-retail/DiscountRetailForm.vue'

export default {
  components: {
    DropdownAbsolute,
    DiscountRetailForm
  },
  setup() {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const data = ref([])
    const sliderActive = ref(false)
    const id = ref('')
    const op = ref()
    const inputSearch = ref('')

    const redirectAdd = () => {
      id.value = ''
      sliderActive.value = true
    }

    const redirectEdit = (currentId: any) => {
      id.value = currentId
      sliderActive.value = true
    }

    const showDropDown = (evt: any, idData: any) => {
      id.value = idData
      op.value.toggle(evt)
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      const response = await discountRetailUseCase.getAll()
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        data.value = response.result
        console.log('data Diskon Retail', data.value)
      }
      store.dispatch('hideLoading')
    }

    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const dataForm = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await discountRetailUseCase.changeIsActive(dataForm)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successChangeStatus, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          isActived(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    const classStatuscard = (val: any) => {
      if (val.IsActive) {
        return 'card-no-shadow card-item-category mb-4'
      }
      return 'card-no-shadow card-item-category mb-4 background-white-darken-1'
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Diskon Retail',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await discountRetailUseCase.deleteData(id.value)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus diskon retail, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            getAllData()
          }
        }
      })
    }

    watch(inputSearch, (val) => {
      console.log(val)
    })

    onMounted(() => {
      getAllData()
    })

    return {
      id,
      op,
      sliderActive,
      data,
      inputSearch,
      redirectAdd,
      redirectEdit,
      changeIsActive,
      closeSidebar,
      getAllData,
      classStatuscard,
      deleteData,
      showDropDown
    }
  }
}

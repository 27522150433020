import { ref } from 'vue'
import DiscountCompany from '@/views/master/customers/discount/discount-company/DiscountCompany.vue'
import DiscountRetail from '@/views/master/customers/discount/discount-retail/DiscountRetail.vue'
import TabsPanelNav from '@/views/shared-components/TabsPanelNav.vue'

export default {
  components: {
    DiscountCompany,
    DiscountRetail,
    TabsPanelNav
  },
  setup() {
    const headers = ref(['Diskon Retail', 'Diskon Perusahaan'])
    const positionTabs = ref(0)
    const tabsChange = (val: any) => {
      positionTabs.value = val
    }
    return {
      headers,
      positionTabs,
      tabsChange
    }
  }
}

import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { discountCompanyUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import InputIconLeftWhite from '@/views/shared-components/form/InputIconLeftWhite.vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  name: 'DiscountCompanyForm',
  components: {
    InputIconLeftWhite
  },
  props: {
    id: {
      default: () => ''
    },
    data: {
      default: () => ''
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const store = useStore()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const id = ref(props.id)
    const dataParent = ref(props.data)
    const minTransaction = ref(0)
    const title = ref('')
    const isActive = ref(true)
    const submitted = ref(false)
    const isSameLevel = ref(false)
    const isSameMinTransaction = ref(false)
    const temp = ref([] as any)
    const dataForm = reactive({
      name: '',
      level: '',
      discount: ''
    })
    const rules = {
      name: {
        required: helpers.withMessage('Nama diskon harus diisi', required)
      },
      level: {
        required: helpers.withMessage('Level diskon harus diisi', required)
      },
      discount: {
        required: helpers.withMessage('Jumlah diskon harus diisi', required)
      }
    }

    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const getDataForm = async () => {
      let response = await discountCompanyUseCase.getDataForm(id.value)
      title.value = response.title
      if (response.data) {
        response = response.data
        if (response.error) {
          $toast.add({
            severity: 'error', detail: 'Gagal mengambil detail data, silahkan coba kembali', group: 'bc', life: 3000
          })
        } else {
          dataForm.name = response.result.Attribute1
          dataForm.level = response.result.Kelas
          dataForm.discount = response.result.Diskon
          isActive.value = response.result.IsActive
          minTransaction.value = response.result.MinTransaksi
          temp.value = response.result
        }
      }
    }

    const proccessSubmit = async () => {
      store.dispatch('showLoading')

      const data = {
        Attribute1: dataForm.name,
        Diskon: Number(dataForm.discount),
        Kelas: Number(dataForm.level),
        MinTransaksi: minTransaction.value,
        IsActive: isActive.value
      }
      // console.log('data', data)
      // eslint-disable-next-line no-use-before-define
      checkProperty(data)
      console.log([isSameLevel.value, isSameMinTransaction.value, temp.value])
      if (isSameLevel.value && dataForm.level !== temp.value.Kelas) {
        // console.log('aa', [data, dataForm, temp])
        $toast.add({
          severity: 'error', detail: 'Level diskon sudah ada', group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else if (((dataForm.level === temp.value.Kelas && data.MinTransaksi !== temp.value.MinTransaksi) || (dataForm.level !== temp.value.Kelas && data.MinTransaksi === temp.value.MinTransaksi)) && isSameMinTransaction.value) {
        // console.log('bb', [data, dataForm, temp])
        $toast.add({
          severity: 'error', detail: 'Terdapat level diskon dengan rata-rata/6 bulan yang sama', group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        console.log('cc', data)
        const response = await discountCompanyUseCase.submitData(id.value, data)
        console.log('lklk =>', response)
        if (response.error) {
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 3000
          })
          store.dispatch('hideLoading')
        } else {
          $toast.add({
            severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
          })
          emit('reloadData')
          closeSidebar()
        }
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      proccessSubmit()
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Diskon Company',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await discountCompanyUseCase.deleteData(id.value)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus diskon retail, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    const checkProperty = (val: any) => {
      console.log([val, temp])
      isSameLevel.value = false
      isSameMinTransaction.value = false
      if ((dataParent.value.some((e: any) => e.Kelas === val.Kelas)) && val.Kelas !== temp.value.Kelas) {
        isSameLevel.value = true
        console.log(dataParent.value.some((e: any) => e.Kelas === val.Kelas))
      }
      if ((dataParent.value.some((e: any) => e.MinTransaksi === val.MinTransaksi)) && val.MinTransaksi !== temp.value.MinTransaksi) {
        isSameMinTransaction.value = true
      }
    }

    onMounted(() => {
      getDataForm()
    })
    return {
      v$,
      dataForm,
      submitted,
      title,
      id,
      minTransaction,
      isActive,
      submitData,
      deleteData,
      closeSidebar,
      temp
    }
  }
}


import {
  ref,
  watch
} from 'vue'

export default {
  name: 'TabsPanelNav',
  emits: ['tabsChange'],
  props: {
    header: {
      default: () => []
    }
  },
  // eslint-disable-next-line
  setup(props: any, { emit }: any) {
    const active = ref(0)
    const arrHeader = ref(props.header)

    watch(active, (newVal, oldVal) => {
      // console.log('new Val', newVal)
      if (newVal !== oldVal) {
        emit('tabsChange', newVal)
      }
    })

    return {
      active,
      arrHeader
    }
  }

}

import {
  ref, watch, onMounted, getCurrentInstance, reactive
} from 'vue'
import { discountCompanyUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import DropdownAbsolute from '@/views/shared-components/DropdownAbsolute.vue'
import DiscountCompanyForm from '@/views/master/customers/discount/discount-company/DiscountCompanyForm.vue'
import { isNumber } from 'lodash'

export default {
  components: {
    DropdownAbsolute,
    DiscountCompanyForm
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $confirm, $toast, $strInd, $numbers
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const data = ref([])
    const sliderActive = ref(false)
    const id = ref('')
    const levelClass = ref(0)
    const op = ref()
    const inputSearch = ref('')
    const paginate = reactive({
      totalRecords: 0,
      skip: 0,
      top: $numbers.totalItemPerPage
    })
    const filters = {
      custom: '',
      isNumber: false
    }
    const route = useRoute()

    const redirectAdd = () => {
      id.value = ''
      sliderActive.value = true
    }

    const redirectEdit = (currentId: any) => {
      id.value = currentId
      sliderActive.value = true
    }

    const showDropDown = (evt: any, idData: any) => {
      levelClass.value = idData.Kelas
      id.value = idData.Id
      op.value.toggle(evt)
    }

    const closeSidebar = () => {
      sliderActive.value = false
    }

    const getAllData = async (reset = false) => {
      store.dispatch('showLoading')
      const response = await discountCompanyUseCase.getAll(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        paginate.totalRecords = response.count
        data.value = response.result
      }
      store.dispatch('hideLoading')
    }

    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const dataForm = {
        Id: val.Id,
        IsActive: val.IsActive
      }
      const response = await discountCompanyUseCase.changeIsActive(dataForm)
      console.log('res', response.message)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successChangeStatus, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      if (val.Kelas > 0) {
        $confirm.require({
          header: 'Status Aktif',
          message: $strInd.confirm.msgChange,
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            isActived(val)
          },
          reject: () => {
            val.IsActive = !val.IsActive
          }
        })
      } else {
        $toast.add({
          severity: 'error', detail: 'Status aktif level diskon tidak dapat diubah', group: 'bc', life: 1500
        })
        val.IsActive = !val.IsActive
      }
    }

    const classStatuscard = (val: any) => {
      if (val.IsActive) {
        return 'card-no-shadow card-item-category mb-4'
      }
      return 'card-no-shadow card-item-category mb-4 background-white-darken-1'
    }

    const deleteData = () => {
      if (levelClass.value > 0) {
        $confirm.require({
          header: 'Diskon Perusahaan',
          message: $strInd.confirm.msgDelete,
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            store.dispatch('showLoading')
            const response = await discountCompanyUseCase.deleteData(id.value)
            if (response.error) {
              $toast.add({
                severity: 'error', detail: response.message, group: 'bc', life: 3000
              })
              store.dispatch('hideLoading')
            } else {
              $toast.add({
                severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
              })
              getAllData()
            }
          }
        })
      } else {
        $toast.add({
          severity: 'error', detail: 'Level diskon tidak dapat dihapus', group: 'bc', life: 1500
        })
      }
    }

    const submitSearch = (val: any = '') => {
      console.log(val)
      // eslint-disable-next-line no-restricted-globals
      console.log(isNaN(val))
      if (val !== '') {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(val)) {
          filters.custom = `(Contains(tolower(Attribute1), '${val}'))`
        } else {
          filters.custom = `(Contains(tolower(Attribute1), '${val}') or Kelas eq ${val})`
        }
      } else {
        filters.custom = ''
      }
      getAllData()
    }

    watch(inputSearch, (val: any) => {
      console.log(val)
      // eslint-disable-next-line no-restricted-globals
      console.log(isNaN(val))
    })

    onMounted(() => {
      getAllData()
    })

    return {
      id,
      op,
      sliderActive,
      data,
      inputSearch,
      route,
      redirectAdd,
      redirectEdit,
      changeIsActive,
      closeSidebar,
      getAllData,
      classStatuscard,
      deleteData,
      showDropDown,
      submitSearch
    }
  }
}

import { ref, onMounted, getCurrentInstance } from 'vue'
import { discountRetailUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import InputIconLeftWhite from '@/views/shared-components/form/InputIconLeftWhite.vue'

export default {
  name: 'DiscountRetailForm',
  components: {
    InputIconLeftWhite
  },
  props: {
    id: {
      default: () => ''
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const store = useStore()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const id = ref(props.id)
    const level = ref('')
    const discount = ref('')
    const minTransaction = ref(0)
    const title = ref('')
    const isActive = ref(false)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const getDataForm = async () => {
      let response = await discountRetailUseCase.getDataForm(id.value)
      title.value = response.title
      if (response.data) {
        response = response.data
        if (response.error) {
          $toast.add({
            severity: 'error', detail: 'Gagal mengambil detail data, silahkan coba kembali', group: 'bc', life: 3000
          })
        } else {
          level.value = response.result.Kelas
          discount.value = response.result.Diskon
          isActive.value = response.result.IsActive
          minTransaction.value = response.result.MinTransaksi
        }
      }
    }

    const submitData = async () => {
      store.dispatch('showLoading')
      const dataForm = {
        Diskon: discount.value,
        Kelas: level.value,
        MinTransaksi: minTransaction.value,
        IsActive: isActive.value
      }

      console.log('dataForm', dataForm)
      const response = await discountRetailUseCase.submitData(id.value, dataForm)

      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Diskon Retail',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await discountRetailUseCase.deleteData(id.value)

          if (response.error) {
            $toast.add({
              severity: 'error', detail: 'Gagal menghapus diskon retail, silahkan coba kembali', group: 'bc', life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successDeleteData, group: 'bc', life: 3000
            })
            emit('reloadData')
            closeSidebar()
          }
        }
      })
    }

    onMounted(() => {
      getDataForm()
    })
    return {
      title,
      id,
      level,
      discount,
      minTransaction,
      isActive,
      submitData,
      deleteData,
      closeSidebar
    }
  }
}
